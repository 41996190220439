/* eslint-disable */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterProducts } from '../../../redux/products/products';
import { useSelector } from 'react-redux';

function FilterElement(props) {
  const categories = useSelector((state) => state.productsReducer.categories);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);

  const handleFilter = () => {
    if (selected) {
      setSelected(false);
    } else {
      setSelected(true);
    }
    dispatch(filterProducts(props.category));
  };

  return (
    <div onClick={handleFilter}
      style={{ borderColor: props.category === categories ? props.color : "#dedede", color: 'black' }}
      className="filter d-flex justify-content-start align-items-center p-2 mx-1"
    >
      {<img className="m-1 rounded" style={{ width: '50px', height: '50px' }} src={props.icon} alt="logo" />}
      <h6 className="pe-2 fw-bolder">{props.title}</h6>
    </div>
  );
}

export default FilterElement;
