/* eslint-disable */
import { useState, useEffect } from "react";
import { ImStarFull } from 'react-icons/im';
import { Link } from 'react-router-dom';
const Sticky = ({ product, setting, width, delevery }) => {

  return (
    <div className="sticky-top bg-white">
      <header className="">
        <div className="d-flex">
          <Link className="d-flex flex-column justify-content-center" to={`/`} style={{ textDecoration: 'none' }}>
            <div className='text-center'>
              <img className="m-1 rounded mt-2" style={{ width: '60px', height: '60px' }} src={setting.img} alt="logo" />
            </div>
          </Link>
          <div className="d-flex flex-column justify-content-center m-2 border-end">
            <div className="d-flex align-items-start">
              <span className="badge bg-warning text-white mx-2">حصريا</span>
              <h5 className="text-center" style={{ overflow: "hidden", textOverflow: 'ellipsis', height: "25px", whiteSpace: "nowrap", maxWidth: `${width - 160}px` }}>
                {product.name}
              </h5>
            </div>
            <div className="d-flex justify-content-between mx-2">
              <div>
                {
                  [...Array(parseInt(product.review)).keys()].map((i) => <ImStarFull key={i} className="ms-1" style={{ color: '#ffc107', fontSize: "20px" }} />)
                }
              </div>
              <h5 className="text-center" style={{ color: setting.color2 }}><b>{parseInt(product.price) + parseInt(delevery)} دج</b></h5>
            </div>
          </div>
        </div>
        <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
      </header>
    </div>
  )
}

export default Sticky

