/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

function Header({ data }) {

  return (
    <>
      <header style={{ backgroundColor: data.color1 }}>
        <p className="text-center text-white p-2 m-0">
          <strong>{data.texting.heading1}</strong>
        </p>
      </header>
      <Link className="text-center" to={`/`} style={{ textDecoration: 'none' }}>
        <div className='text-center my-2'>
          <img className="m-1 rounded" style={{ width: '50px', height: '50px' }} src={data.img} alt="logo" />
        </div>
      </Link><hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />

    </>
  );
}

export default Header;
