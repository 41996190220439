/* eslint-disable */
import React, { useEffect } from 'react';
import Products from '../components/products/products';
import ReactHtmlParser from 'react-html-parser';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { FiShoppingCart } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import './thnaks.css';

function Thanks({ setting }) {
    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init(setting.facebookPixel, advancedMatching, options);
    console.log(setting.facebookPixel)
    useEffect(() => {

        ReactPixel.pageView(); // For tracking page view
        ReactPixel.track('Purchase', state.total);

    }, [])
    const { state } = useLocation();

    function hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    return (
        <div>
            <Header data={setting} />
            <div >
                <div className='d-flex align-items-center flex-column my-1'>
                    {ReactHtmlParser(setting.texting.thanks)}
                </div>
                <div className='d-flex justify-content-center my-1'>
                    <img className="m-1 rounded" style={{ width: '300px', height: '300px' }} src={setting.img} alt="logo" />
                </div>

                <div className='d-flex justify-content-center my-1'>
                    <div className="mt-3 recu mx-3 border">
                        <div className="border w-100 px-3 py-2" style={{ backgroundColor: '#F7F8F1' }} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded={true} aria-controls="collapseExample">
                            <FiShoppingCart className="rounded " style={{ color: setting.color2,height: "23px", width: "40px" }}  />
                            <b style={{ color: setting.color2 }}>ملخص الطلبية</b>
                        </div>
                        <div className="collapse1" id="collapseExample2">
                            <div className="card border-0 card-body p-0">
                                <div className="d-flex justify-content-between align-items-center px-4" style={{ height: '50px' }}>
                                    <div>
                                        <b>{state.product.name}</b>
                                    </div>
                                    <p className='mt-3'><span className="badge text-white" style={{ backgroundColor: setting.color2 }}>x {state.qty}</span> {state.product.price}دج</p>
                                </div>
                                <hr className="m-0" style={{ width: '100%' }} />
                                <div className="d-flex justify-content-between align-items-center  px-4" style={{ height: '50px' }}>
                                    <div className=''>
                                        <p className="m-0 p-0">🚚سعر التوصيل</p>
                                    </div>
                                    <p className="fw-lighter mt-3">
                                        {state.deleveryPrice !== 0 ? state.state + (state.deleveryPrice + "دج") : <span className="badge bg-success text-white mx-2">مجانا</span>}
                                    </p>
                                </div>
                                <hr className="m-0" style={{ width: '100%' }} />
                                <div className="d-flex justify-content-between align-items-center px-4" style={{ height: '50px' }}>
                                    <div>
                                        <h5>المجموع</h5>
                                    </div>
                                    <h5 style={{ color: setting.color2 }}><b>{state.total} دج</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 m-0" style={{ width: '100%' }}>
                    <section className="pt-4 text-center">
                        <h2><b style={{ fontSize: '27px', textDecoration: `underline  ${setting.color2}`, textUnderlinePosition: 'under', textDecorationColor: `${hexToRGB(setting.color2, 0.3)}`, textDecorationThickness: '5px' }}>منتجات أخرى تهمك</b></h2>
                        <p className="text-black-50">{setting.texting.heading4}</p>
                    </section>
                    <Products data={setting} />
                </div>
            </div>

            <Footer data={setting} />
        </div>
    );
}

export default Thanks;
