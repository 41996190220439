/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Header from '../components/header/header';
import Carousels from '../components/details/carousel/carousel';
import Sticky from '../components/details/sticky/sticky';
import Intro from '../components/details/intro/intro';
import Form from '../components/details/form/form';
import Info from '../components/details/info/info';
import Products from '../components/products/products';
import Footer from '../components/footer/footer';
import ReactPixel from 'react-facebook-pixel';
import { TbPhoneCall } from 'react-icons/tb';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import './details.css';


function Details({ setting }) {

  const [delevery, setDelevery] = useState(10);
  const [show, setShow] = useState(false);
  let params = useParams()
  const products = useSelector((state) => state.itemsReducer);
  const product = products.filter((product) => product.url === params.id)[0]

  const advancedMatching = { em: 'some@email.com' };
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(setting.facebookPixel, advancedMatching, options);
  useEffect(() => {
    ReactPixel.pageView();
  }, [])





  useEffect(() => {
    window.scrollBy(0, -3000)
  }, [product])

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  const scroll = () => {
    const section = document.querySelector('#contact-us');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  return (
    <>
      {
        <div>
          <a href={`tel:${setting.SocialMedia.phone}`}>
            <button className='whatsup'>{<TbPhoneCall className="rounded " size={35} />}</button>
          </a>

          {(scrollPosition > 1400 || scrollPosition < 300) && windowDimenion.winWidth < 998 &&
            <div className='w-100 buy2 d-flex justify-content-center bg-white' style={{ height: '70px' }}>
              <button className='buy mx-auto my-2' style={{ backgroundColor: setting.color2 }} onClick={() => { scroll() }}>للطلب إضغط هنا</button>
            </div>
          }


          <Header data={setting} />
          {scrollPosition > 100 && windowDimenion.winWidth < 998 &&
            <Sticky product={product} setting={setting} width={windowDimenion.winWidth} delevery={delevery} />
          }

          <div className="product-container row mt-4 m-0 mx-auto">
            <div className="col-12 col-lg-5 h-100 sticky-lg-top mb-5">
              {windowDimenion.winWidth < 998 &&
                <>
                  <Intro product={product} data={setting} />
                  <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
                  <p className='mt-1 text-muted'>{product.short}</p>
                </>
              }
              <div className='w-100 corsol'>
                <Carousels images={product.gallery} />
              </div>
            </div>
            <div className="col-12 col-lg-7 d-flex flex-column px-3 px-sm-5">
              {windowDimenion.winWidth >= 998 && <Intro product={product} data={setting} />}
              <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
              {windowDimenion.winWidth >= 998 && <p className='mt-1 text-muted'>{product.short}</p>}
              <div>
                <Form product={product} show={show} setShow={setShow} data2={setting} setting={setting} setDelevery={setDelevery} />
                <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
                <div className='text-center pt-3 text-editor'>
                  {ReactHtmlParser(product.textEditor)}
                </div>
                <Info setting={setting} />
              </div>
            </div>
          </div>
          {<hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />}
          <div className="row mt-4 m-0" style={{ width: '100%' }}>
            <section className="pt-4 text-center">
              <h2><b style={{ fontSize: '27px', textDecoration: `underline  ${setting.color2}`, textUnderlinePosition: 'under', textDecorationColor: `${hexToRGB(setting.color2, 0.3)}`, textDecorationThickness: '5px' }}>منتجات أخرى تهمك</b></h2>
              <p className="text-black-50">{setting.texting.heading4}</p>
            </section>
            <Products data={setting} />
          </div>
          <Footer data={setting} />
        </div>}
    </>

  );
}

export default Details;
