/* eslint-disable */
const LOGIN_USER = 'LOGIN_USER';

const initialState = "49zkg8sWUpNDoWkBddSkS3jaXPY2";

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return action.type;
    default:
      return state;
  }
};

export default reducer;
