/* eslint-disable */
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookSquare } from 'react-icons/fa';

function Footer({ data }) {
  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <footer className="row bg-light mt-3 mx-auto border-top p-4" style={{ height: '100%', width: '100%' }}>
      <div className="col-sm-6 col-12 d-flex align-items-center justify-content-center">
        <div>
          <BsInstagram style={{ color: "gray" }} className="m-2 p-2" size={50} onClick={() => handleClick(data.SocialMedia.insta)} />
        </div>

        <FaFacebookSquare style={{ color: "gray" }} className="m-2 p-2" size={50} onClick={() => handleClick(data.SocialMedia.facebook)} />
      </div>
      <div className="col-sm-6 col-12 text-center">
        <img className="m-2 rounded" style={{ width: '50px', height: '50px', filter: "grayscale(100%)" }} src={data.img} alt="logo" />
        <p className='text-muted'>{data.footer}</p>
      </div>
    </footer>
  );
}

export default Footer;
