const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
const FILTER_PRODUCTS = 'FILTER_PRODUCTS';

const initialState = {
  categories: 'كل المنتجات',
  products: [{
    id: '1',
    name: 'product 1',
    image: '',
    review: 5,
    price: 300,
    oldPrice: 450,
    category_id: 1,
  }],
};

export const fetchProducts = (payload) => ({
  type: FETCH_PRODUCTS,
  payload,
});

export const filterProducts = (payload) => ({
  type: FILTER_PRODUCTS,
  payload,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return { ...state, products: action.payload };
    case FILTER_PRODUCTS:
      return { ...state, categories: action.payload };

    default:
      return state;
  }
};

export default reducer;
