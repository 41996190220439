/* eslint-disable */
import FilterElement from './filterElement/filterElement';
import './Filter.css';

function Filter({ data }) {
  return (
    <section className="d-flex justify-content-start mx-auto mt-5 row filter-contanier">
      {data.categories[4] && data.categories[1] && data.categories[2] && data.categories[3] && <FilterElement color={data.color2} category={data.categories[0]} title={<b>{data.categories[0]}</b>} icon={data.categories[5]} />}
      {data.categories[1] && <FilterElement color={data.color2} category={data.categories[1]} title={<b>{data.categories[1]}</b>} icon={data.categories[6]} />}
      {data.categories[2] && <FilterElement color={data.color2} category={data.categories[2]} title={<b>{data.categories[2]}</b>} icon={data.categories[7]} />}
      {data.categories[3] && <FilterElement color={data.color2} category={data.categories[3]} title={<b>{data.categories[3]}</b>} icon={data.categories[8]} />}
      {data.categories[4] && <FilterElement color={data.color2} category={data.categories[4]} title={<b>{data.categories[4]}</b>} icon={data.categories[9]} />}
    </section>

  );
}

export default Filter;
