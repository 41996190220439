/* eslint-disable */
import { useSelector } from 'react-redux';
import Product from './product/product';
import './Products.css';

function Products({ data }) {
  const categories = useSelector((state) => state.productsReducer.categories);
  const products = useSelector((state) => state.itemsReducer);

  return (
    <section className="row my-5 mx-auto product-container">
      {
        products.filter((product) => categories.includes(product.category_id) || categories === "كل المنتجات" && product.show === "1").map((product) => (
          <Product key={product.id} product={product} data={data} />
        ))
      }
    </section>
  );
}

export default Products;
