/* eslint-disable */
import React from 'react'
import { ImStarFull } from 'react-icons/im';

const Intro = ({ product, data }) => {
  return (
    <>
      <div className=" align-items-center">
        <h3 className="">
          <b style={{fontSize:'23px'}}>{product.name}</b>
          <span style={{fontSize:'20px'}} className="badge bg-warning text-white m-1 me-3">حصريا</span>
        </h3>
        
      </div>
      <div className="">
        {
          [...Array(parseInt(product.review)).keys()].map((i) => <ImStarFull key={i} className="ms-1" style={{ color: '#ffc107',fontSize:"20px" }} />)
        }
      </div>
      <div className="d-flex align-items-center">
        <p>
          <span className="badge p-1 fs-6" style={{ color: 'white', backgroundColor: data.color2 }}>
            {parseInt((parseInt(product.oldPrice)-parseInt(product.price) )*100/parseInt(product.oldPrice))}%-
          </span>
        </p>
        <p className="fs-3 fw-bold pe-3" style={{ color: data.color1 }}>
          {product.price}
          <span>دج</span>
        </p>
        <p className="text-decoration-line-through text-black-50 fs-3 pe-3">
          {product.oldPrice}
          <span>دج</span>
        </p>
      </div>
    </>
  )
}

export default Intro
