/* eslint-disable */
import ReactHtmlParser from 'react-html-parser';

function Info({setting}) {

  return (
    <>
      <div className="mt-3 text-muted">
      <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
        <div className="py-2">
            {ReactHtmlParser(setting.texting.return)}
        </div>
        <div className="py-2">
        {ReactHtmlParser(setting.texting.pay)}
        </div>
        <div>
          <p>من أجل عملية الشراء  ، قم بالضغط على زر شراء الأن</p>
        </div>
      </div>
    </>
  )
}

export default Info
