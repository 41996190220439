/* eslint-disable */
import ImageGallery from 'react-image-gallery';

function Carousels({ images }) {

  return (
    <div className=" ">
      <div style={{ textAlign: "center" }}>
        <div style={{
          padding: "0 0px"
        }}
          className="">
          <ImageGallery
            className=""
            showNav={false}
            showPlayButton={false}
            showFullscreenButton={false}
            isRTL={true}
            showThumbnails={images.length !== 1}
            thumbnailStyle
            items={images.map((i) => ({
              original: i,
              thumbnail: i,
              originalHeight: "450px",
              originalWidth: "100px",
              thumbnailHeight: "70px",
              originalClass: "img-fluid",
              thumbnailClass :'gallery'
            }))}
          />
        </div>
      </div>
    </div>
  );
}

export default Carousels;
