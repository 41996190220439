/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ImStarFull } from 'react-icons/im';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';

function Product(props) {
  const categories = useSelector((state) => state.productsReducer.categories);
  const [isFocused, setFocus] = useState(false);

  return (
    <Link className={`${!!categories && 'product'} p-0 mx-sm-2 mx-1 my-2`} to={`/${props.product.url}`} style={{ textDecoration: 'none' }} state={{ product: props.product }}>
      {
        <Card className="product-card shadow-sm"
          onMouseEnter={() => setFocus(true)}
          onMouseLeave={() => setFocus(false)}
          style={{ borderColor: isFocused ? props.data.color2 : "" }}
        >
          <Card.Body className='p-0'>
            <div className='div-image'>
              <img src={!!props.product.image ? props.product.image : "https://foorweb.net/safir/safirproduct.png"} className="p-2 product-img rounced" alt="..." />
            </div>

            <div className="card-body p-0 my-0">
              <p className="card-title text-center text-dark p-0 m-0"><b>{props.product.name}</b></p>
              <div className="d-flex justify-content-center my-1">
                {
                  [...Array(parseInt(props.product.review)).keys()].map((i) => <ImStarFull key={i} className="ms-1" style={{ color: '#ffc107', fontSize: "20px" }} />)
                }
              </div>
              <div className="card-title d-flex justify-content-around px-1" style={{ height: '21px' }}>

                <b style={{ color: props.data.color2 }}>
                  {props.product.price}
                  <span>دج</span>
                </b>
                <p className="text-decoration-line-through text-black-50">
                  {props.product.oldPrice}
                  <span>دج</span>
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
        /*
        
        */
      }

    </Link>
  )
}

export default Product;
