/* eslint-disable */
function Slogn({ data }) {
  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  return (
    <section className="pt-4 text-center">
      <h1 className="deco font-weight-bold">
        <b style={{ fontSize: '27px', textDecoration: `underline  ${data.color2}`, textUnderlinePosition: 'under', textDecorationColor: `${hexToRGB(data.color2, 0.3)}`, textDecorationThickness: '5px' }}>{data.texting.heading2}</b>
      </h1>
      <p className="text-black-50">{data.texting.heading3}</p>
    </section>
  );
}

export default Slogn;
