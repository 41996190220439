/* eslint-disable */  
import { useState, useEffect } from "react";
import { FiShoppingCart } from 'react-icons/fi';
import Form from 'react-bootstrap/Form';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';
import { datat } from './data';
import { useNavigate } from 'react-router-dom'; 
import './form.css';
import { useSelector } from 'react-redux';

function Form2({ product, data2, setting, setDelevery }) {
  const user = useSelector((state) => state.userReducer);
  const [num, setNum] = useState(7);
  function randomNumberInRange(min, max) {

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  useEffect(() => {
    const interval = setInterval(() => {

      setNum(randomNumberInRange(7, 40));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const axios = require('axios');
  const navigate = useNavigate();
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState(
    product.options.map((option => ({ option: "" })))
  )
  const [fakeMessage, setFakeMessage] = useState(false)

  const [data, setData] = useState({
    productId: product.id,
    name: "",
    phone: "",
    state: "",
    paid: "0",
    address: "",
    commune: "",
    deleveryStatus: "1",
    deleveryPrice: 0,
    qty: 1,
    total: product.price,
    paymentType: "دفع عند الاستلام",
    timeStamp: serverTimestamp(),
  });

  const fakeorders = (e) => {
    e.preventDefault()
    let now = new Date().getTime();
    const oneDay = 1000 * 60 * 60 * parseInt(setting.reqTime);

    if (!!localStorage.getItem("expireTime")) {
      if (parseInt(localStorage.getItem("expireTime")) > now) {
        if (!!localStorage.getItem("requsetNumber")) {
          if (parseInt(localStorage.getItem("requsetNumber")) < parseInt(setting.reqNumber)) {
            localStorage.setItem("requsetNumber", parseInt(localStorage.getItem("requsetNumber")) + 1);
            setFakeMessage(false)
            handleSubmit(e)
          } else {
            setFakeMessage(true)
          }
        } else {

          localStorage.setItem("requsetNumber", parseInt(1));
        }


      } else {
        localStorage.setItem("expireTime", now + oneDay);
        localStorage.setItem("requsetNumber", parseInt(1));
        setFakeMessage(false)
        handleSubmit(e)
      }
    } else {
      localStorage.setItem("expireTime", now + oneDay);
      setFakeMessage(false)
      handleSubmit(e)
      if (!!localStorage.getItem("requsetNumber")) {
        localStorage.setItem("requsetNumber", parseInt(localStorage.getItem("requsetNumber")) + 1);
      } else {
        localStorage.setItem("requsetNumber", parseInt(1));
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = addDoc(collection(db, "orders"), { ...data, user: user, total: (product.price * data.qty) + data.deleveryPrice, options: product.options.map((option, index) => option.title + ': ' + selectedOptions[index].option).join('-') }).then(
      navigate('/thanks', { state: { ...data, total: (product.price * data.qty) + data.deleveryPrice, product: product } })
    );
    axios.post(`${setting.googleShit}?action=addUser`, {
      name: data.name,
      product: product.name,
      phone: data.phone,
      state: data.state,
      commune: data.commune,
      qty: data.qty,
      price: product.price,
      deleveryPrice: data.deleveryPrice,
      total: (product.price * data.qty) + data.deleveryPrice,
      options: product.options.map((option, index) => option.title + ': ' + selectedOptions[index].option).join('-')
    },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(function (response) {
        setData({
          productId: product.id,
          name: "",
          phone: "",
          state: "",
          paid: "0",
          address: "",
          commune: "",
          deleveryStatus: "1",
          deleveryPrice: 0,
          qty: 1,
          total: "0",
          paymentType: "دفع عند الاستلام",
          timeStamp: serverTimestamp(),
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    //Runs only on the first render
    if (data.state !== "" && setting.delevery.paid) {
      const price = setting.delevery.states.filter((state) => state.name === data.state)[0]
      setData({ ...data, deleveryPrice: parseInt(price.price) })
    } else {
      setData({ ...data, deleveryPrice: parseInt(0) })
    }
  }, [data.state]);

  const updateFieldChanged = (index, name, e) => {
    let newArr = selectedOptions.map((item, i) => {
      if (index == i) {
        return { ...item, [name]: e };
      } else {
        return item;
      }
    });
    setSelectedOptions(newArr);
  }

  useEffect(() => {
    setDelevery(data.deleveryPrice)
  }, [data.deleveryPrice]);


  return (
    <>

      <form
        className="bg-light pt-3 rounded form2"
        style={{ borderColor: data2.color2 }}
        onSubmit={fakeorders}
        id="contact-us"
      >
        <div className="d-flex mx-3 mb-4">
          {
            product.offers.map((offer, index) => (
              !!offer.title &&
              <div
                className="rounded py-1 px-1 border"
                style={{ fontSize: "12px", marginLeft: "3px", backgroundColor: selectedOffer === index && data2.color2, color: selectedOffer === index && "white" }}
                key={index}
                onClick={() => { setSelectedOffer(index), setData({ ...data, qty: parseInt(offer.qty), total: offer.price }) }}>
                {offer.title}
              </div>
            ))
          }
        </div>
        <div className="mb-3 px-3 row">
          <div className="col-6 px-1">
            <input
              type="text"
              placeholder="الإسم"
              className=" p-2 mb-1"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              required
            />
          </div>
          <div className="col-6 px-1">
            <input
              type="tel"
              placeholder="رقم الهاتف"
              className=" p-2-2 "
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="mb-0 px-3 row">
          <div className={`px-1 ${setting.delevery.activeCities ? 'col-6 ' : 'col-12'}`}>
            <select className="form-select" required placeholder="الولاية" value={data.state} onChange={(e) => setData({ ...data, state: e.target.value, deleveryPrice: e.target.price })} className="form-control p-2">
              <option value="">الولاية</option>
              {
                setting.delevery.paid && setting.delevery.states.filter((state) => state.hide).map((state) => (
                  <option key={state.wilaya_code} value={state.name} price={175}>{state.name}</option>
                ))
              }
              {
                setting.delevery.free && setting.delevery.states.map((state) => (
                  <option key={state.wilaya_code} value={state.name} price={175}>{state.name}</option>
                ))
              }
            </select>
          </div>
          {
            setting.delevery.activeCities && <div className="col-6 px-1">
              <select class="form-select" required placeholder="البلدية" disabled={!data.state} value={data.commune} onChange={(e) => setData({ ...data, commune: e.target.value })} className="form-control1 p-2">
                <option value="">البلدية</option>
                {
                  datat.filter((wliya) => wliya.wilaya_name === data.state).map((state) => (
                    <option key={state.commune_name} value={state.commune_name}>{state.commune_name}</option>
                  ))
                }
              </select>
            </div>
          }

        </div>
        <div className="px-2 py-2 d-flex justify-content-between">
          <p className="m-0 p-0">🚚سعر التوصيل</p>
          <p className="m-0 p-0">
            {
              data2.delevery.free ?
                <span className="badge bg-success text-white mx-2">مجانا</span> :
                !!data.state ? data.deleveryPrice : 'اختر الولاية'
            }
          </p>
        </div>
        {
          data2.delevery.address && <div className="mb-3 px-3 row">
            <div className="col-12 px-1">
              <input
                type="text"
                placeholder="العنوان الكامل"
                required
                value={data.address}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                className="form-control p-2"
              />
            </div>
          </div>
        }

        {product.options.map((option, index) => (
          <div className="px-3" key={index}>
            <p className="fs-5"><b>{option.title}</b></p>
            <div className="row my-3">
              {
                !!option.colorOption && option.colorOption.map((o, i) => (
                  <div className=" my-1">
                    <div className="">
                      {
                        option.color === "1" ?
                          <div onClick={(e) => { updateFieldChanged(index, 'option', o.option) }} className={`rounded ${selectedOptions[index].option === o.option ? "border border-dark border-2" : ""}  mx-1 p-1`} style={{ backgroundColor: o.color, color: "white", width: "40px", height: "30px" }} ></div>
                          :
                          <div className={`rounded ${selectedOptions[index].option === o.option ? "border border-dark border-2" : ""}  mx-1 p-1`} style={{ backgroundColor: "white", color: "black" }} onClick={(e) => { updateFieldChanged(index, 'option', o.option) }}>{o.option}</div>
                      }
                    </div>
                  </div>
                ))

              }
              {
                !!option.option4 && <div className="">
                  <div className="d-flex justify-content-center">
                    {
                      option.color === "1" ?
                        <div onClick={(e) => { updateFieldChanged(index, 'option', option.option4) }} className={`rounded ${selectedOptions[index].option === option.option4 ? "border border-dark border-2" : ""}  mx-1 p-1`} style={{ backgroundColor: option.color4, color: "white", width: "40px", height: "30px" }} ></div>
                        :
                        <div className={`rounded ${selectedOptions[index].option === option.option4 ? "border border-dark border-2" : ""}  mx-1 p-1`} style={{ backgroundColor: "white", color: "black" }} onClick={(e) => { updateFieldChanged(index, 'option', option.option4) }}>{option.option4}</div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        ))
        }
        <div className="row px-3">
          {
            <div className="col-3 px-1 d-flex justify-content-between align-items-start">
              <button type="button" disabled={data.qty === 1} className="btn btn-light border" onClick={() => { setData({ ...data, qty: data.qty - 1, total: (data.qty - 1) * product.price }), setSelectedOffer(null) }} style={{ width: '40px' }}><b>-</b></button>
              <p className="fw-bolder pt-1">{data.qty}</p>
              <button type="button" className="btn btn-light border" onClick={() => { setData({ ...data, qty: data.qty + 1, total: (data.qty + 1) * product.price }), setSelectedOffer(null) }} style={{ width: '40px' }}><b>+</b></button>
            </div>
          }
          <div className="col-9 px-1">
            <button
              type="submit"
              
              className="btn btn-primary w-100" style={{ backgroundColor: data2.color2 }}
            >
              أطلب الآن
            </button>
          </div>
        </div>
        {fakeMessage && parseInt((parseInt(localStorage.getItem("expireTime")) - new Date().getTime()) / 1000) > 0 &&
          <>
            <p className="text-danger text-center">لتجنب الطلبات المزيفة ، لا يمكنك تقديم أكثر من {setting.reqNumber} باستخدام نفس الجهاز</p>
            <p className="text-success text-center">الوقت المتبقي {parseInt((parseInt(localStorage.getItem("expireTime")) - new Date().getTime()) / 1000)} ثانية</p>
          </>}
        <div className="mt-3">
          <div className="border w-100 px-1 py-2" style={{ backgroundColor: '#F7F8F1' }} data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <FiShoppingCart className="rounded " style={{ color: data2.color2, height: "23px", width: "40px"}}  /> 
            <b style={{ color: data2.color2 }}>ملخص الطلبية</b>
          </div>
          <div className="collapse" id="collapseExample">
            <div className="card border-0 card-body p-0">
              <div className="d-flex justify-content-between align-items-center pt-2 px-2" style={{ height: '70px' }}>
                <div>
                  <b>{product.name}</b>
                </div>
                <p><span className="badge text-white" style={{ backgroundColor: data2.color2 }}>x {data.qty}</span> {product.price}دج</p>
              </div>
              <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
              <div className="d-flex justify-content-between align-items-center pt-2 px-2" style={{ height: '70px' }}>
                <p className="m-0 p-0">🚚سعر التوصيل</p>
                <p className="m-0 p-0">
                  {
                    data2.delevery.free ?
                      <span className="badge bg-success text-white mx-2">مجانا</span> :
                      !!data.state ? data.deleveryPrice : 'اختر الولاية'
                  }
                </p>

              </div>
              <hr className="m-0" style={{ backgroundColor: 'black', height: "1px", opacity: "0.1" }} />
              <div className="d-flex justify-content-between align-items-center pt-2 px-2" style={{ height: '70px' }}>
                <div>
                  <h5>المجموع</h5>
                </div>
                <h5 style={{ color: data2.color2 }}><b>{parseInt(data.total) + parseInt(data.deleveryPrice)} دج</b></h5>
              </div>
            </div>
          </div>
        </div>
      </form>
      <p className="text-center my-3"><b>يشاهده <span className="badge text-white" style={{ backgroundColor: data2.color2 }}>{num}</span> متصفح في الوقت الحالي</b></p>
    </>
  )
}

export default Form2
