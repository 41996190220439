/* eslint-disable */
import React, { useEffect } from 'react';
import Header from '../components/header/header';
import Slogn from '../components/slogn/slogn';
import Filter from '../components/filter/filter';
import Products from '../components/products/products';
import Footer from '../components/footer/footer';
import ReactPixel from 'react-facebook-pixel';
import Spinner from 'react-bootstrap/Spinner';

function Main({ setting }) {
  const advancedMatching = { em: 'some@email.com' };
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(setting.facebookPixel, advancedMatching, options);
  useEffect(() => {

    ReactPixel.pageView()

  }, [])

  return (
    <div>
        <Header data={setting} />
        <Slogn data={setting} />
        <Filter data={setting} />
        <Products data={setting} />
        <Footer data={setting} />
    </div>
  );
}

export default Main;
