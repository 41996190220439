/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
} from 'react-router-dom';
import Main from './screens/main';
import Details from './screens/details';
import Thanks from './screens/thanks';
import { collection, doc, setDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from './firebase';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { fetchStore } from './redux/store/store';
import { fetchProducts } from './redux/items/items';
import Spinner from 'react-bootstrap/Spinner';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const store = useSelector((state) => state.storeReducer);

  const fetchData = () => {
    const unsub = onSnapshot(query(collection(db, "store"), where("user", "==", user)), (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() })
      });
      dispatch(fetchStore(list[0]));
    });

    const unsub2 = onSnapshot(query(collection(db, "products"), where("user", "==", user)), (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() })
      });
      dispatch(fetchProducts(list.filter((product) => product.deleted !== 1)));
    });
  }

  const [setting, setSetting] = useState("");
  useEffect(() => {

    setSetting(store)

  }, [store])

  useEffect(() => {

    fetchData()

  }, [])

  useEffect(() => {

    !!setting.id && setDoc(doc(db, "store", setting.id), { ...setting, visits: setting.visits + 1 })

  }, [setting.id])

  return (
    <HelmetProvider>
      {!!setting ? <div className="App">
        <Helmet>
          <link rel="icon" href={setting.logo} />
          <title>{setting.title}</title>
          <meta name="theme-color" content={setting.color2} />
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={<Navigate to={"/"} />}
            />
            <Route path="/" element={<Main setting={setting} />} />
            <Route path="/:id" element={<Details setting={setting} />} />
            <Route path="/thanks" element={<Thanks setting={setting} />} />
          </Routes>
        </BrowserRouter>
      </div> : <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>}
    </HelmetProvider>
  );
}

export default App;
