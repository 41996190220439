/* eslint-disable */
// configureStore.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import productsReducer from './products/products';
import storeReducer from './store/store';
import itemsReducer from './items/items';
import userReducer from './user/user';
const reducer = combineReducers({ productsReducer, storeReducer, itemsReducer, userReducer });

const store = createStore(
  reducer,
  applyMiddleware(logger, thunk),
);

export default store;
